import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../assets/styles/main.scss"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Helmet from "react-helmet"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import InlineSVG from "../components/inline-svg"
import ConditionalWrapper from "./conditional-wrapper"

type LayoutProps = {
  children: any;
  container?: boolean;
  className?: string;
  bodyClass?: string;
}

const Layout = ({ children, className, bodyClass, container = true }: LayoutProps) => {

    const {pillars, people, logo} = useStaticQuery(query);

    return (
        <>
            <Helmet>
                <title>BHK Crisis Communications</title>
                <html lang="en" />
                <body className={bodyClass} />
            </Helmet>
            <header>
                <Navbar bg="dark" variant='dark' expand="lg" className='mb-3'>
                    <Container fluid='xl'>
                        <Link to="/" className="navbar-brand mr-0 mr-md-4" title='BHK Crisis Communications. Experienced, Knowledgeable, Leaders'>
                          <InlineSVG svg={logo.svg}/>
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto mr-auto">
                              {pillars.nodes.map(pillar => (
                                <Link to={`/${pillar.slug}`} className='nav-link' activeClassName='active' key={pillar.id}>{pillar.name}</Link>
                              ))}
                            </Nav>

                            <Nav>
                                <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/solutions">Solutions</Link>
                                <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/news">News</Link>
                                <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/team">Team</Link>
                                <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/our-work">Our Work</Link>
                                <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/contact">Contact</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <ConditionalWrapper
              condition={container}
              wrapper={children =>
                <Container className={className} fluid='xl'>{children}</Container>
              }
            >{children}</ConditionalWrapper>
            <footer className='bg-dark text-light py-3 mt-5'>
                <Container fluid='xl'>
                    <Row xs={1} md={2} className='align-items-center text-center text-white-75'>
                        <Col className='text-md-right order-md-1'>
                            <div className='mb-2'>
                              {people.nodes.map((person, index) => {
                                let spacer = index > 0 ? <>
                                  <span className='d-none d-sm-inline'>&nbsp;&middot;&nbsp;</span>
                                  <br className='d-sm-none'/>
                                </> : '';
                                return(<span key={person.id}>
                                  {spacer}
                                  <a href={`tel:${person.phoneNumber}`} className='mx-1 my-1 my-sm-0 d-inline-block'>{person.phoneNumber}</a>
                                </span>)
                              })}
                            </div>
                            <a href="mailto:info@bhkcrisiscommunications.com">info@bhkcrisiscommunications.com</a>
                        </Col>
                        <Col className='text-md-left mt-3 mt-md-0'>
                          <span className='mr-1'>&copy; BHK Crisis Communications</span>
                          <span className='d-none d-sm-inline'>&nbsp;&middot;&nbsp;</span>
                          <br className='d-sm-none'/>
                          <Link to="/site/terms" className='text-white-75 mx-1'>Terms</Link>&nbsp;&middot;&nbsp;
                          <Link to="/site/privacy" className='text-white-75 mx-1'>Privacy</Link>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Layout;

const query = graphql`
  query {
    pillars: allContentfulPillar(sort: {fields: index}) {
      nodes {
        id
        name
        slug
      }
    }

    people: allContentfulPerson(sort: {fields: index}, filter: {active: {eq: true}}) {
      nodes {
        id
        phoneNumber
      }
    }

    logo: contentfulAsset(contentful_id: {eq: "4iIcUv8FUTKwycQx8GNto1"}) {
      svg {
        absolutePath
        dataURI
        content
        relativePath
      }
    }
  }
`
